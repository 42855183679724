import React from "react"
import { Global } from "@emotion/core"

import { globalStyles } from "../styles"
import SEO from "./seo"
import "../styles/layout.css"

const Layout = ({ children, title }) => (
  <>
    <Global styles={globalStyles} />
    <SEO
      title={title}
      description="清水艺术：专注日本美术类升学，创造知名美大合格奇迹"
    />
    {children}
  </>
)

export default Layout
