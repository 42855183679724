import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import { colors, weights, container, fonts } from "../../styles"

const Menu = ({ menuOpen, toggleOpen }) => {
  const textFadeIn = css`
    position: relative;
    opacity: ${menuOpen ? "1" : "0"};
    transform: translateY(${menuOpen ? "0" : "50%"});
    transition-property: color, transform, opacity;
    transition-timing-function: ease-out;
    transition-duration: 0.5s;

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: ${colors.darkgray};
      transition: inherit;
      height: ${menuOpen ? "0" : "100%"};
    }
  `

  const mobileDelay1 = css`
    transition-delay: 0s, 0.1s, 0.1s;
  `

  const mobileDelay2 = css`
    transition-delay: 0s, 0.2s, 0.2s;
  `

  const mobileDelay3 = css`
    transition-delay: 0s, 0.3s, 0.3s;
  `

  const mobileDelay4 = css`
    transition-delay: 0s, 0.4s, 0.4s;
  `

  const mobileDelay5 = css`
    transition-delay: 0s, 0.5s, 0.5s;
  `

  const mobileDelay6 = css`
    transition-delay: 0s, 0.6s, 0.6s;
  `
  const mobileDelay7 = css`
    transition-delay: 0s, 0.7s, 0.7s;
  `

  const mobileDelay8 = css`
    transition-delay: 0s, 0.8s, 0.8s;
  `

  const linkBaseStyles = css`
    display: block;
    color: white;
    font-weight: ${weights.medium};
    letter-spacing: -0.2px;
    text-align: center;

    &:hover,
    &:focus {
      color: ${colors.white};
    }
  `
  const linkPrimaryStyle = css`
    font-size: 28px;
    font-family: ${fonts.sans};
    line-height: 57px;
  `

  return (
    <nav
      css={css`
        position: fixed;
        width: 100vw;
        display: flex;
        align-items: center;
        background-color: ${colors.darkgray};
        transition: 0.3s ease all;
        overflow: hidden;
        z-index: 3;
        top: ${menuOpen ? "0" : "100vh"};
        left: 0;
        flex-direction: column;
        justify-content: center;
        height: ${menuOpen ? "auto" : "0"};
        min-height: ${menuOpen ? "100vh" : "0"};
        padding: 0;
      `}
    >
      <div
        css={[
          container.max,
          css`
            padding-top: 80px;
            padding-bottom: 60px;
          `,
        ]}
      >
        <section>
          <Link
            onClick={() => toggleOpen()}
            css={[linkPrimaryStyle, textFadeIn, linkBaseStyles, mobileDelay1]}
            to="/"
          >
            清水艺术
          </Link>
          <Link
            css={[linkPrimaryStyle, textFadeIn, linkBaseStyles, mobileDelay2]}
            onClick={() => toggleOpen()}
            to="/about"
          >
            关于清水
          </Link>
          <Link
            css={[linkPrimaryStyle, textFadeIn, linkBaseStyles, mobileDelay3]}
            onClick={() => toggleOpen()}
            to="/schools"
          >
            学校介绍
          </Link>
        </section>

        <section>
          <Link
            css={[linkPrimaryStyle, textFadeIn, linkBaseStyles, mobileDelay4]}
            onClick={() => toggleOpen()}
            to="/class/normal"
          >
            学部课程
          </Link>
          <Link
            css={[linkPrimaryStyle, textFadeIn, linkBaseStyles, mobileDelay5]}
            onClick={() => toggleOpen()}
            to="/class/master"
          >
            大学院课程
          </Link>
        </section>

        <section>
          <Link
            onClick={() => toggleOpen()}
            css={[linkPrimaryStyle, textFadeIn, linkBaseStyles, mobileDelay6]}
            to="/education"
          >
            EDUCATION
          </Link>
          <Link
            css={[linkPrimaryStyle, textFadeIn, linkBaseStyles, mobileDelay7]}
            onClick={() => toggleOpen()}
            to="/studio"
          >
            STUDIO
          </Link>
          <Link
            css={[linkPrimaryStyle, textFadeIn, linkBaseStyles, mobileDelay8]}
            onClick={() => toggleOpen()}
            to="/brand"
          >
            BRAND
          </Link>
        </section>
      </div>
    </nav>
  )
}

export default Menu
