import React from "react"
import { css } from "@emotion/core"
import { fonts, mediaQueries } from "../../styles/css-utils"

export const navlinkStyle = css`
  color: white;
  &:hover {
    color: #dad7d6;
  }
`

export default () => {
  const handleScroll = () => {
    window.scrollTo({ top: 0, right: 0, behavior: "smooth" })
  }

  return (
    <div
      css={css`
        grid-area: 25 / 1 / 26 / 11;
        background-color: #030b5d;
        color: white;
        font-family: ${fonts.sans};
        display: flex;
        justify-content: space-between;
        padding: 5px 20px;
        ${mediaQueries.phoneLarge} {
          display: none;
        }
      `}
    >
      <div
        css={css`
          cursor: pointer;
          &:before {
            content: "";
            height: 15px;
            width: 15px;
            border-radius: 50%;
            position: relative;
            top: 1px;
            margin-right: 5px;
            background-color: white;
            display: inline-block;
          }
        `}
        onClick={handleScroll}
      >
        返回顶部
      </div>
      <div>
        <span
          css={css`
            margin-right: 40px;
          `}
        >
          TEL:{" "}
          <a href="tel:0368753371" css={navlinkStyle}>
            +81 03-6875-3371
          </a>
        </span>
        <span>
          WECHAT:{" "}
          <a href="weixin://" css={navlinkStyle}>
            kiyomizu_art
          </a>
        </span>
      </div>
    </div>
  )
}
