/* eslint-disable import/prefer-default-export */
/* Variables and functions specifically for CSS-in-JS use */

/* Media queries */
const breakpoints = [480, 900, 1220]
const names = [`xs`, `phoneLarge`, `desktop`]

export const mediaQueries = breakpoints.reduce((acc, bp, i) => {
  acc[names[i]] = `@media (max-width: ${bp}px)`
  return acc
}, {})

export const jsBreakpoints = breakpoints.reduce((acc, bp, i) => {
  acc[names[i]] = bp
  return acc
}, {})

/* Fonts */
export const fonts = {
  serif: `"Noto Serif", "Noto Serif SC", serif`,
  sans: `-apple-system, "Noto Sans", "Helvetica Neue", Helvetica, "Nimbus Sans L", Arial, "Liberation Sans", "PingFang SC", "Hiragino Sans GB", "Noto Sans CJK SC", "Source Han Sans SC", "Source Han Sans CN", "Microsoft YaHei", "Wenquanyi Micro Hei", "WenQuanYi Zen Hei", "ST Heiti", SimHei, "WenQuanYi Zen Hei Sharp", sans-serif;`,
  mono: `monospace`,
}

export const weights = {
  thin: `100`,
  light: `300`,
  regular: `400`,
  medium: `500`,
  bold: `700`,
  black: `900`,
}

/* Colors - Use a RGB to HEX converter */
export const colors = {
  deepblue: `rgb(1, 9, 90)`,
  darkgray: `rgb(35, 35, 35)`,
  lightgray: `rgb(219, 216, 215)`,
  normalgray: `rgb(239, 239, 239)`,
  lightblue: `rgb(14, 29, 129)`,
  white: `rgb(249, 249, 249)`,
}
